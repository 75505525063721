import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

import data from "../../../../data/calendar";

const link = "/school-events/world-dance-day-2024";

const eventData = data.find((item) => item.link.includes(link));
const imagesSrc = eventData.images;
const heading = eventData.title;
const textHTML = eventData.text;

export default function WorldDanceDay2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {imagesSrc.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                {!item.includes(".mp4") ? (
                  <img alt="" src={item}></img>
                ) : (
                  <video controls src={item}></video>
                )}
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">{heading}</h1>

        <p dangerouslySetInnerHTML={{ __html: textHTML }}></p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
